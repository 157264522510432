import React, { useContext, useEffect } from "react";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";
import { Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Seo
import { PageSeo } from "../components/seo/page-seo";

// Context
import { PageSetup } from "../components/context/page-setup";

const Page = styled.div`
  margin: 150px 0 75px 0;
  padding: 0 20px;

  @media (max-width: 768px) {
    margin: 50px 0 30px 0;
    padding: 0;
  }

  min-height: calc(${(props) => props.height}px - 50px - 42px - 75px);

  & .page-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
  }

  & .empty-page-container {
    flex: 100%;
    width: 100%;

    margin: 100px 0 0 0;

    & p {
      margin: 0;
      text-align: center;

      @media (max-width: 768px) {
        &.message {
          font-size: 14px;
          line-height: 20px;
        }
      }

      & a {
        display: block;
        max-width: 220px;
        margin: 30px auto 0 auto;

        font-size: 13px;
        line-height: 39px;
        letter-spacing: 0.05em;
        text-align: center;
        font-weight: 500;
        background-color: #000;
        color: #fff;

        height: 40px;
        transition: 250ms color ease, 250ms background-color ease;

        @media (max-width: 768px) {
          max-width: 180px;
          margin: 20px auto 0 auto;

          font-size: 11px;
          letter-spacing: 0.1em;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }
`;

const NotFoundPage = () => {
  // Page Setup
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  const height = use100vh();

  useEffect(() => {
    setPageSetup({
      pageColor: `#fff`,
      pageType: `404`,
    });
  }, []);

  return (
    <>
      <PageSeo
        seoTitle={`Page Not Found | SHANNON BOND`}
        seoText={null}
        seoImage={null}
      />
      <Page height={height}>
        <div className="page-container">
          <div className="empty-page-container">
            <p className="message cardo">Page Not Found</p>
            <p className="">
              <Link to="/" className="uppercase">
                View Latest Collection
              </Link>
            </p>
          </div>
        </div>
      </Page>
    </>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);
